import React, { useEffect, useState } from "react";
import BlogsModel from "../components/blogs/blogs-model";
import RichTextEditor from "../components/support-components/rich-text-editor";
import { Container, useTheme, Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Text from "../components/data-display/text";
import moment from "moment";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FileCopySharpIcon from "@material-ui/icons/FileCopySharp";
const BlogsView = ({ pageContext: itemContext }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [content, setContent] = useState<BlogsModel>();
  let item: BlogsModel = new BlogsModel(itemContext);
  useEffect(() => {
    if (item && item.content) {
      const parsedObj = JSON.parse(item.content.toString());
      item.content = parsedObj;
      setContent(item);
    }
  }, []);

  return (
    <div style={{ paddingTop: "150px", backgroundColor: "white" }}>
      <div>
        {/* {content && content.content && (
          <RichTextEditor value={content.content} placeholder={""} readOnly />
        )} */}
        <Container
          maxWidth={"xl"}
          style={{
            padding: smDown ? "0 5%" : mdDown ? "0 10%" : "0 15%",
          }}
        >
            <Grid item lg={12} style={{marginBottom:'15px'}}>
              <Typography  variant={"h2"} style={{ color: "rgb(40,40,40)" , fontWeight:'bold' }}>
                {item.title}{" "}
              </Typography>
              
            </Grid>
            <Grid item lg={12} style={{marginTop:'15px' , marginBottom:'15px'}}>
              <Text black variant={"body1"} style={{ color: "gray" }}>
                {item.subTitle}{" "}
              </Text>
            </Grid>
          <Grid container>
        
            <Grid item lg={6}>
              <Grid
                container
                style={{ marginTop: "60x", marginBottom: "30px" }}
              >
                <div>
                  {" "}
                 { item?.avatar.length >0?
                <img
                src={item?.avatar}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50px",
                  marginTop: "2px",
                }}
              />:''  }  
                </div>
                <Grid item lg={9} style={{ marginTop: "15px" }}>
                  {" "}
                  <Text
                    variant={"subtitle2"}
                    bold
                    style={{ color: "black", marginLeft: "16px" }}
                  >
                    {item.author}
                    <br />
                  </Text>
                  <Text
                    variant={"caption"}
                    style={{ color: "grey", marginLeft: "16px" }}
                  >
                    {moment(item.updateAt).format("dddd, MMM DD, hh:mm A")}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
           
            <Grid item lg={12} style={{}}>
            <img
                src={item.image}
                alt="Avatar"
                style={{
                  width: "100%",
                  height: "90%",
                  marginTop: "20px",
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>

          <RichTextEditor
            value={JSON.parse(item.content.toString())}
            placeholder={""}
            readOnly
          />
        </Container>
      </div>
    </div>
  );
};

export default BlogsView;
